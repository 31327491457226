
import {
  PropType,
  defineComponent,
  ref,
} from "vue";
import { IProducts } from "@/interfaces/IProduct";
import { Icon } from "@iconify/vue";
import { getDoc } from "@firebase/firestore";
import { db } from "@/firebase";
import { deleteDoc, doc, onSnapshot } from "firebase/firestore";

export default defineComponent({
  name: "DisplayProductsCart",
  components: {
    Icon,
  },
  props: {
    product: {
      type: Object as PropType<IProducts>,
      required: true,
    },
  },

  data() {
    return {
      env: process.env.VUE_APP_API_URL,
      doc: [{}],
      colorsCart: 0,
      id: '',
      size: '',
    };
  },

  methods: {
    async getCartProducts() {
      onSnapshot(doc(db, 'CartItems', this.product.id), (snap: any) => {
        this.colorsCart = snap.data()?.color || ''
        this.id = snap.data()?.id 
        this.size = snap.data()?.size || ''
      })
    },


    async removeCartItem() {
      await deleteDoc(doc(db, 'CartItems', this.product.id)) 
    },
  },

  created() {
      this.getCartProducts()
  },
});
