
import { defineComponent } from "vue";
import ModalCartAndWishlist from "./ModalCartAndWishlist.vue";
import DisplayProductsWishlist from "./DisplayProductsWishlist.vue";

export default defineComponent({
  name: "listaDeDesejos",
  components: {
    ModalCartAndWishlist,
    DisplayProductsWishlist,
  },
  emits: ["fechar", "show"],
  data () {
        return {
            wishlistItems: [ 
            {
                id: 1,
                img: 'https://img.freepik.com/vetores-gratis/modelo-de-camisa-branca_1132-95.jpg?2&w=826&t=st=1691413762~exp=1691414362~hmac=8f9e75638ed0b1eaa8656424d4851502a944ce2edea1d8aacaf59c55538f383f',
                img2: 'https://img.freepik.com/fotos-gratis/homem-e-mulher-com-camisas-polo-brancas-basicas-fotos-de-estudio-de-roupas_53876-104297.jpg?w=1380&t=st=1691414399~exp=1691414999~hmac=c91d823e3f4ac25bc019cfe97d1ac46e9004b5c05c1c926a1628dea6c50f7868',
                nome: 'Camisa Enerzee',
                preco: 'R$120,00',
                clothing: true,
                colors: true,
            
            },
            {
                id: 2,
                img: 'https://img.freepik.com/psd-gratuitas/placas-de-energia-solar-em-renderizacao-3d-realista_625553-173.jpg?w=740&t=st=1691413847~exp=1691414447~hmac=7dc82873cf977ba5abc41da0e81ad182a9da49a54be40cb540c73018cd1a3859',
                img2: 'https://img.freepik.com/psd-gratuitas/placas-de-energia-solar-em-renderizacao-3d-realista_625553-171.jpg?w=740&t=st=1691414336~exp=1691414936~hmac=b21cf949a83319132004f8201819fa6ed42f9141594079cc45740ca46c7a1945',
                nome: 'Painel solar',
                preco: 'R$120,00',
                clothing: false,
                colors: false,
            
            },
            {
                id: 3,
                img: 'https://img.freepik.com/fotos-premium/ciclista-urbana-tendo-um-freio-na-e-bicicleta_23-2148225823.jpg?w=1380',
                img2: 'https://img.freepik.com/fotos-gratis/pessoa-usando-scooter-eletrico-na-cidade_23-2149341734.jpg?w=1380&t=st=1691414099~exp=1691414699~hmac=f35fb48d4effe714f5a8360f85cf978697e1990644064279483c725b48b4cb60',
                nome: 'Bicicleta elétrica',
                preco: 'R$120,00',
                clothing: false,
                colors: true,
            
            },
            {
                id: 4,
                img: 'https://img.freepik.com/fotos-premium/ciclista-urbana-tendo-um-freio-na-e-bicicleta_23-2148225823.jpg?w=1380',
                img2: 'https://img.freepik.com/fotos-gratis/pessoa-usando-scooter-eletrico-na-cidade_23-2149341734.jpg?w=1380&t=st=1691414099~exp=1691414699~hmac=f35fb48d4effe714f5a8360f85cf978697e1990644064279483c725b48b4cb60',
                nome: 'Bicicleta elétrica',
                preco: 'R$120,00',
                clothing: false,
                colors: true,
            
            },
            {
                id: 5,
                img: 'https://img.freepik.com/fotos-premium/ciclista-urbana-tendo-um-freio-na-e-bicicleta_23-2148225823.jpg?w=1380',
                img2: 'https://img.freepik.com/fotos-gratis/pessoa-usando-scooter-eletrico-na-cidade_23-2149341734.jpg?w=1380&t=st=1691414099~exp=1691414699~hmac=f35fb48d4effe714f5a8360f85cf978697e1990644064279483c725b48b4cb60',
                nome: 'Bicicleta elétrica',
                preco: 'R$120,00',
                clothing: false,
                colors: true,
            
            },
            {
                id: 6,
                img: 'https://img.freepik.com/fotos-premium/ciclista-urbana-tendo-um-freio-na-e-bicicleta_23-2148225823.jpg?w=1380',
                img2: 'https://img.freepik.com/fotos-gratis/pessoa-usando-scooter-eletrico-na-cidade_23-2149341734.jpg?w=1380&t=st=1691414099~exp=1691414699~hmac=f35fb48d4effe714f5a8360f85cf978697e1990644064279483c725b48b4cb60',
                nome: 'Bicicleta elétrica',
                preco: 'R$120,00',
                clothing: false,
                colors: true,
            
            },
            {
                id: 7,
                img: 'https://img.freepik.com/fotos-premium/ciclista-urbana-tendo-um-freio-na-e-bicicleta_23-2148225823.jpg?w=1380',
                img2: 'https://img.freepik.com/fotos-gratis/pessoa-usando-scooter-eletrico-na-cidade_23-2149341734.jpg?w=1380&t=st=1691414099~exp=1691414699~hmac=f35fb48d4effe714f5a8360f85cf978697e1990644064279483c725b48b4cb60',
                nome: 'Bicicleta elétrica',
                preco: 'R$120,00',
                clothing: false,
                colors: true,
            
            },
            {
                id: 8,
                img: 'https://img.freepik.com/fotos-premium/ciclista-urbana-tendo-um-freio-na-e-bicicleta_23-2148225823.jpg?w=1380',
                img2: 'https://img.freepik.com/fotos-gratis/pessoa-usando-scooter-eletrico-na-cidade_23-2149341734.jpg?w=1380&t=st=1691414099~exp=1691414699~hmac=f35fb48d4effe714f5a8360f85cf978697e1990644064279483c725b48b4cb60',
                nome: 'Bicicleta elétrica',
                preco: 'R$120,00', 
                clothing: false,
                colors: true,
            
            },
        ]
    }
  }
});

