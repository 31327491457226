
import { computed, defineComponent, ref } from 'vue';
import ModalCartAndWishlist from './ModalCartAndWishlist.vue';
import DisplayProductsCart from './DisplayProductsCart.vue';
import { SHOW_PRODUCTS, SHOW_SIZES } from '@/store/type-actions';
import { useStore } from '@/store';

export default defineComponent({
    name: "carrinhoEnerzee",
    components: {
        ModalCartAndWishlist,
    DisplayProductsCart
},
    props: ['fechar', 'show'],

    setup() {
    const store = useStore();
    store.dispatch(SHOW_PRODUCTS);
    store.dispatch(SHOW_SIZES);

    return {
      product: computed(() => store.state.products),
      size: computed(() => store.state.sizes)
    };
  },

})
