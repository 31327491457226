import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-row justify-between items-center p-6 border-b-4 border-black" }
const _hoisted_2 = { class: "font-bold text-xl text-black" }
const _hoisted_3 = { class: "text-black relative flex flex-col flex-nowrap h-4/5 overflow-x-scroll px-4" }
const _hoisted_4 = { class: "border-t-4 border-black flex justify-center p-6" }
const _hoisted_5 = { class: "rounded bg-black text-white px-10 py-3 font-bold hover:px-12 hover:py-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["z-50 fixed flex flex-col justify-start w-full px-2 h-full top-0 bg-white sm:w-96 transition-all duration-500", _ctx.show ? 'right-0' : '-right-full'])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h2", _hoisted_2, [
        _renderSlot(_ctx.$slots, "titulo")
      ]),
      _createVNode(_component_Icon, {
        icon: "teenyicons:x-solid",
        class: "text-cinza-padrao hover:cursor-pointer",
        onClick: _ctx.fechar
      }, null, 8, ["onClick"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "produtos")
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("button", _hoisted_5, [
        _renderSlot(_ctx.$slots, "botao")
      ])
    ])
  ], 2))
}