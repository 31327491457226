import axios from "axios";

const TOKEN = localStorage.getItem('user')

const httpClient = axios.create ({
  baseURL: process.env.VUE_APP_API_URL,
  headers: { 
    "Authorization": `Bearer ${TOKEN}`,
    "Content-type": "application/json",
   },
  }
);

function login() {
      const response = axios
        .post(process.env.VUE_APP_API_URL + "api/login", {
          email: "api",
          password: 123123
        })
        .then(response => response.data.token)
        .then(data => 

          localStorage.setItem('user', data)     
        )
        .catch((error: any) => console.log(error))
      }
login();      

export default httpClient;
