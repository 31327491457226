
import { defineComponent } from 'vue';
import { Icon } from '@iconify/vue';


export default defineComponent({
    name: "FooterEcommerce",
    components: {
		Icon,
	},
})
