import { createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex justify-start flex-wrap" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DisplayProductsWishlist = _resolveComponent("DisplayProductsWishlist")!
  const _component_ModalCartAndWishlist = _resolveComponent("ModalCartAndWishlist")!

  return (_openBlock(), _createBlock(_component_ModalCartAndWishlist, {
    fechar: _ctx.onFechar,
    show: _ctx.onShow
  }, {
    titulo: _withCtx(() => [
      _createTextVNode(" Minha lista de desejos: ")
    ]),
    produtos: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.wishlistItems, (wishlistItem) => {
          return (_openBlock(), _createBlock(_component_DisplayProductsWishlist, {
            key: wishlistItem.id,
            id: wishlistItem.id,
            colors: wishlistItem.colors
          }, {
            WishlistProductImage: _withCtx(() => [
              _createElementVNode("img", {
                src: wishlistItem.img,
                class: "w-full h-full"
              }, null, 8, _hoisted_2)
            ]),
            WishlistProductName: _withCtx(() => [
              _createTextVNode(_toDisplayString(wishlistItem.nome), 1)
            ]),
            WishlistProductPrice: _withCtx(() => [
              _createTextVNode(_toDisplayString(wishlistItem.preco), 1)
            ]),
            _: 2
          }, 1032, ["id", "colors"]))
        }), 128))
      ])
    ]),
    botao: _withCtx(() => [
      _createTextVNode(" Adicionar todos ao carrinho ")
    ]),
    _: 1
  }, 8, ["fechar", "show"]))
}