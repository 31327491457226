
import { defineComponent } from 'vue';
import { Icon } from '@iconify/vue';


export default defineComponent({
    name: "ModalSearchBar",
    components: {
        Icon,
    },
    props: ['fechar', 'show']
})
