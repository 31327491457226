
import { PropType, defineComponent, ref } from "vue";
import { IProducts } from "@/interfaces/IProduct";
import { ISizes } from "@/interfaces/ISizes";
import { db } from "../firebase"
import { collection, addDoc, doc, setDoc } from "firebase/firestore";

export default defineComponent({
  name: "ProdutosEnerzee",
  components: {},
  props: {
    product: {
      type: Object as PropType<IProducts>,
      required: true,
    },
    sizes: {
      type: Object as PropType<ISizes[]>,
    },
  },
  data() {
    return {
      showOptions: false,
      quickAdd: false,
      photos: false,
      getSize: "",
      env: process.env.VUE_APP_API_URL,
      localStorageProducts: [
        {
          id: "",
          color: "",
          size: "",
        },

      ],
    };
  },

  mounted() {
    this.setColor();
    this.ableSizes();
  },

  methods: {

      async createProduct(size: string) {

        const docRef = await setDoc(doc(db, 'CartItems', this.product.id), {
          id: this.product.id || '',
          size: size || '',
          color: this.checkedInputs || ''
        })
      },

    quickAddfunction() {
      this.quickAdd = true;
      if (this.product.img.length > 1) {
        this.photos = true;
      }
    },
    closeQuickAdd() {
      this.quickAdd = false;
      this.photos = false;
    },
    showOptionsFunction() {
      if (this.sizesOfProduct.length === 1) {
        this.showOptions = false;
      } else {
        this.showOptions = true;
      }
    },
    closeOptions() {
      this.showOptions = false;
    },

    // sizesForColors() {
    //     const findingIdColorProduct = this.product?.option_product.map(id => id.option.map(idColor => idColor.color_product))
    //     console.log(this.colorOfTheSize)
    //     this.colorOfTheSize.push(findingIdColorProduct.toString())

    //     const gettingIdcolor = this.colorOfTheSize.map(id => parseInt(id))
    //     console.log(gettingIdcolor)
    //     if(gettingIdcolor === this.checkedInputs) {
    //       this.filterSizesByColor = true
    //     } else {
    //       this.filterSizesByColor = false
    //     }
    //   },

    ableSizes() {
      const findingOptionsOfProduct = this.product.option_product.map(
        (options) => options
      );
      const findingSizesOfProduct = findingOptionsOfProduct?.map((sizes) =>
        sizes.option.map((size) =>
          size.size_product.map((sizeName) => sizeName.name)
        )
      );
      for (let name = 0; name < findingSizesOfProduct.length; name++) {
        const key = findingSizesOfProduct[name];
        this.sizesOfProduct.push(key.toString());
      }
    },

    setColor() {
      const colors = this.product.color.find((colors) => colors);
      const attributes = colors?.attributes.find((color) => color);
      const color = attributes?.id;
      this.checkedInputs = color;
    },

    chosenSize(size: string) {
      this.getSize = size;
    },
  },
  setup() {
    const checkedInputs = ref();
    const options = ref();
    const sizesStore = ref();
    const sizesOfProduct = ref([""]);
    const colorOfTheSize = ref([""]);
    return {
      checkedInputs,
      options,
      sizesStore,
      sizesOfProduct,
      colorOfTheSize,
    };
  },
});
