
import { defineComponent } from "vue";
import ModalOptionsHeader from "./ModalOptionsHeader.vue";

export default defineComponent({
  name: "modalOptionsHeader",
  components: {
    ModalOptionsHeader,
  },
  data () {
    return {
        departaments: [
            {
                name: "Sobre",
                suboptions: ['']
            },
            {
                name: "Seja um integrador",
                suboptions: ['']
            },
        ]
    }
  }
});
