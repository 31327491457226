import { createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DisplayProductsCart = _resolveComponent("DisplayProductsCart")!
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_ModalCartAndWishlist = _resolveComponent("ModalCartAndWishlist")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_ModalCartAndWishlist, {
      fechar: _ctx.fechar,
      show: _ctx.show
    }, {
      titulo: _withCtx(() => [
        _createTextVNode(" Meu carrinho: ")
      ]),
      produtos: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.product, (cartItem) => {
          return (_openBlock(), _createElementBlock("div", {
            key: cartItem.id
          }, [
            _createVNode(_component_DisplayProductsCart, { product: cartItem }, null, 8, ["product"])
          ]))
        }), 128))
      ]),
      botao: _withCtx(() => [
        _createVNode(_component_RouterLink, { to: "/continuar-compra" }, {
          default: _withCtx(() => [
            _createTextVNode(" Continuar compra ")
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["fechar", "show"])
  ]))
}