
import { defineComponent, reactive, computed } from "vue";
import useValidate from "@vuelidate/core";
import { required, email, minLength, sameAs } from "@vuelidate/validators";

export default defineComponent({
  name: "FormsRegister",

  methods: {
    submitForm() {
      this.v$.$validate(); // checks all inputs
    },
  },

  setup() {
    const state = reactive({
      name: "",
      cpf: "",
      date: "",
      number: "",
      email: "",
      password: {
        password: "",
        confirm: "",
      },
    });

    const rules = computed(() => {
      return {
        name: { required },
        cpf: { required, minLength: minLength(14) },
        date: { required, minLength: minLength(10) },
        number: { required, minLength: minLength(16) },
        email: { required, email },
        password: {
          password: { required, minLength: minLength(6) },
          confirm: { required, sameAs: sameAs(state.password.password) },
        },
      };
    });
    const v$ = useValidate(rules, state);
    return { state, v$ };
  },
});
