
import { defineComponent } from 'vue';
import Header from './components/Header.vue';

export default defineComponent({
name: "appEnerzee",
components: {
    Header,
}
})
