import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'

export const firebaseApp = initializeApp({
    apiKey: "AIzaSyDJUPgAXtgk_6CH6tEaGHzgD2CF2mltbEc",

    authDomain: "enerzee-ecommerce.firebaseapp.com",
  
    databaseURL: "https://enerzee-ecommerce-default-rtdb.firebaseio.com",
  
    projectId: "enerzee-ecommerce",
  
    storageBucket: "enerzee-ecommerce.appspot.com",
  
    messagingSenderId: "629083807609",
  
    appId: "1:629083807609:web:87f62594e110b1f43599a8",
  
    measurementId: "G-W1S74MXZ0J"
  
})


export const db = getFirestore(firebaseApp)


// here we can export reusable database references
// export const todosRef = collection(db, 'todos')