import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "w-screen h-full flex justify-center items-center" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CarouselSlides = _resolveComponent("CarouselSlides")!
  const _component_Carousel = _resolveComponent("Carousel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Carousel, {
      onNext: _ctx.next,
      onPrev: _ctx.prev
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.slides, (slide, index) => {
          return (_openBlock(), _createBlock(_component_CarouselSlides, {
            key: index,
            index: index,
            visibleSlide: _ctx.visibleSlide,
            direction: _ctx.direction,
            class: "w-screen right-0 left-0"
          }, {
            default: _withCtx(() => [
              _createElementVNode("img", {
                src: slide,
                alt: "carousel",
                class: "w-screen h-screen"
              }, null, 8, _hoisted_2)
            ]),
            _: 2
          }, 1032, ["index", "visibleSlide", "direction"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["onNext", "onPrev"])
  ]))
}