import { IProducts } from '@/interfaces/IProduct';
import { InjectionKey } from 'vue';
import { Store, createStore, useStore as vuexUseStore } from 'vuex'
import http from '@/http';
import { SHOW_PRODUCTS, SHOW_SIZES } from './type-actions';
import { DEFINE_PRODUCTS, DEFINE_SIZES } from './type-mutations';
import { ISizes } from '@/interfaces/ISizes';

export interface State {
  products: IProducts[],
  sizes: ISizes[]
}

export const key: InjectionKey<Store<State>> = Symbol()


export const store = createStore<State>({
  state: {
    products: [],
    sizes: []
  },    

  mutations: {
    [DEFINE_PRODUCTS](state, product: IProducts[]) {
      state.products = product
    },
    [DEFINE_SIZES](state, size: ISizes[]) {
      state.sizes = size
    },
  },  
    actions: {
      [SHOW_PRODUCTS]({ commit }) {
        http.get('api/product')
        .then(response => response.data)
        .then(data => commit(DEFINE_PRODUCTS, data.data))
        .catch((error: any) => console.log(error))
      },
      [SHOW_SIZES]({ commit }) {
        http.get('api/size')
        .then(response => response.data)
        .then(data => commit(DEFINE_SIZES, data.data))
        .catch((error: any) => console.log(error))
      },
    },
    
      
})

export function useStore(): Store<State> {
  return vuexUseStore(key)
}
