import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-center" }
const _hoisted_2 = { class: "relative flex justify-between w-screen items-end text-5xl text-white h-screen py-10 px-28" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "default", { class: "absolute" }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Icon, {
        icon: "cil:arrow-left",
        onClick: _ctx.prev,
        class: "relative hover:cursor-pointer"
      }, null, 8, ["onClick"]),
      _createVNode(_component_Icon, {
        icon: "cil:arrow-right",
        onClick: _ctx.next,
        class: "relative hover:cursor-pointer"
      }, null, 8, ["onClick"])
    ])
  ]))
}