
import { defineComponent } from "vue";
import { Icon } from "@iconify/vue";
import HeadersIcon from "./HeadersIcon.vue";
import ModalSearchBar from "./ModalSearchBar.vue";
import Cart from "./Cart.vue";
import Wishlist from "./Wishlist.vue";
import ModalInstitucional from "./ModalInstitucional.vue";
import ModalConsultant from "./ModalConsultant.vue";
import ModalIntegrator from "./ModalIntegrator.vue";
import ModalStore from "./ModalStore.vue";

export default defineComponent({
  name: "HeaderEnerzee",
  components: {
    Icon,
    HeadersIcon,
    ModalSearchBar,
    Cart,
    Wishlist,
    ModalInstitucional,
    ModalConsultant,
    ModalIntegrator,
    ModalStore,
},
  data() {
    return {
      searchBar: false,
      carrinho: false,
      listaDesejos: false,
      AppearInstitucional: false,
      AppearConsultant: false,
      AppearIntegrator: false,
      AppearStore: false,
      changeColors: false,
      options: ['Institucional', 'Consultor', 'Integrador', 'Loja'],
    };
  },
  methods: {
    changeColorsMouseEvent() {
      this.changeColors = true;
    },
    changeColorsMouseEvent2() {
      this.changeColors = false;
    },
    botaoSearchBar() {
      this.searchBar = !this.searchBar;
    },
    botaoCarrinho() {
      this.carrinho = !this.carrinho;
    },
    botaoListaDesejos() {
      this.listaDesejos = !this.listaDesejos;
    },

    showInstitucional() {
      this.AppearInstitucional = true;
      this.AppearConsultant = false;
      this.AppearIntegrator = false;
      this.AppearStore = false;
    },

    showConsultant() {
      this.AppearConsultant  = true;
      this.AppearInstitucional = false;
      this.AppearIntegrator = false;
      this.AppearStore = false;
    },
    showIntegrator() {
      this.AppearIntegrator = true;
      this.AppearConsultant  = false;
      this.AppearInstitucional = false;
      this.AppearStore = false;
    },
    showStore() {
      this.AppearStore = true;
      this.AppearConsultant  = false;
      this.AppearInstitucional = false;
      this.AppearIntegrator = false;
    },

    fecharMenus() {
      this.AppearInstitucional = false;
      this.AppearConsultant = false;
      this.AppearIntegrator = false;
      this.AppearStore = false
    },
  },
});
