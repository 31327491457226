
import { defineComponent, ref } from 'vue';
import CarouselSlides from '@/components/CarouselSlides.vue';
import Carousel from '@/components/Carousel.vue';


export default defineComponent({
   name: "SlidesCarrosel",
   components: {
       Carousel,
       CarouselSlides
   },
   data() {
       return{
           visibleSlide: 0,
           direction: '',
           slides: ['https://img.freepik.com/fotos-gratis/painel-de-energia-solar-fotovoltaica-no-campo-verde-limpo-conceito-de-energia-alternativa-ai-generative_123827-23424.jpg?w=1380&t=st=1691168278~exp=1691168878~hmac=6a0e17bae14f745b11ba16a3727c3610260b6d09467e3e31f94a5e5b7e89abc6', 'https://img.freepik.com/fotos-gratis/homem-trabalhador-no-firld-pelos-paineis-solares_1303-15571.jpg?w=1380&t=st=1691168282~exp=1691168882~hmac=9acf99a837d9702c634964ffaec2b89bd69fd55a2ab3c4c5e26a7bcd64c24d08']
       }
     },
     
     mounted() {
   this.startSlide()
 },
    
 methods: {
   startSlide () {
     this.timer1 = setInterval(this.next, 8000);
   },
   next() {
     if (this.visibleSlide >= this.slides.length - 1) {
       this.visibleSlide = 0;
     } else {
       this.visibleSlide++;
     }
     this.direction = "left"
     clearInterval(this.timer1)
     this.timer1 = setInterval(this.next, 8000);
   },
   prev() {
     if (this.visibleSlide <= 0) {
       this.visibleSlide = this.slides.length - 1;
     } else {
       this.visibleSlide--;
     }
     this.direction = "right"
     clearInterval(this.timer1)
     this.timer1 = setInterval(this.next, 8000);
   },
 },

 setup() {
  const timer1= ref()
  
  return {
    timer1
  }
 }
 
})
