
    import { defineComponent } from 'vue';
    import { Icon } from '@iconify/vue';
    
    export default defineComponent({
        name: "CarouselEnerzee",
        components: {
            Icon
        },
        methods: {
            next() {
                this.$emit('next')
            },
            prev() {
                this.$emit('prev')
            }
        }
    })
    