
import  FormsRegister from "@/components/FormsRegister.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "userRegister",
  components: {
    FormsRegister,
  },
});
