import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'
import Catalog from '../views/GeneralCatalog.vue'
import UserRegister from '../views/UserRegister.vue';
import BuyingScreen from '../views/BuyingScreen.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/catalogo',
    name: 'catalog',
    component: Catalog
  },
  {
    path: '/register-user',
    name: 'register User',
    component: UserRegister
  },
  {
    path: '/continuar-compra',
    name: 'Buying screen',
    component: BuyingScreen
  },

]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
