
import { defineComponent } from 'vue';
// import Produtos from '@/components/Produtos.vue';



export default defineComponent({
    name: "GeneralCatalog",
    components: {
        // Produtos
    },
    data () {
        return {
            destaques: [ 
            {
                id: 1,
                img: 'https://img.freepik.com/vetores-gratis/modelo-de-camisa-branca_1132-95.jpg?2&w=826&t=st=1691413762~exp=1691414362~hmac=8f9e75638ed0b1eaa8656424d4851502a944ce2edea1d8aacaf59c55538f383f',
                img2: 'https://img.freepik.com/fotos-gratis/homem-e-mulher-com-camisas-polo-brancas-basicas-fotos-de-estudio-de-roupas_53876-104297.jpg?w=1380&t=st=1691414399~exp=1691414999~hmac=c91d823e3f4ac25bc019cfe97d1ac46e9004b5c05c1c926a1628dea6c50f7868',
                nome: 'Camisa Enerzee',
                preco: 'R$120,00',
                clothing: true,
                colors: [
                    {
                        color: '#2D247A',
        
                    },
                    {
                        color: '#000',
                        
                    },
                    {
                        color: '#fff',
                        images : [ 
                            {
                                id: 0,
                                img:'https://img.freepik.com/fotos-premium/ciclista-urbana-tendo-um-freio-na-e-bicicleta_23-2148225823.jpg?w=1380',
                            },
                            {
                                id: 1,
                                img: 'https://img.freepik.com/fotos-gratis/pessoa-usando-scooter-eletrico-na-cidade_23-2149341734.jpg?w=1380&t=st=1691414099~exp=1691414699~hmac=f35fb48d4effe714f5a8360f85cf978697e1990644064279483c725b48b4cb60',
                            }
                        ]
                     
                    }
                ]
                  
            },
            {
                id: 54,
                img: 'https://img.freepik.com/vetores-gratis/modelo-de-camisa-branca_1132-95.jpg?2&w=826&t=st=1691413762~exp=1691414362~hmac=8f9e75638ed0b1eaa8656424d4851502a944ce2edea1d8aacaf59c55538f383f',
                img2: 'https://img.freepik.com/fotos-gratis/homem-e-mulher-com-camisas-polo-brancas-basicas-fotos-de-estudio-de-roupas_53876-104297.jpg?w=1380&t=st=1691414399~exp=1691414999~hmac=c91d823e3f4ac25bc019cfe97d1ac46e9004b5c05c1c926a1628dea6c50f7868',
                nome: 'Camisa Enerzee',
                preco: 'R$120,00',
                clothing: true,
                colors: [
                    {
                        color: '#2D247A',
                        images : [ 
                            {
                                id: 0,
                                img: 'https://img.freepik.com/vetores-gratis/modelo-de-camisa-branca_1132-95.jpg?2&w=826&t=st=1691413762~exp=1691414362~hmac=8f9e75638ed0b1eaa8656424d4851502a944ce2edea1d8aacaf59c55538f383f',
                            },
                            {
                                id: 1,
                                img: 'https://img.freepik.com/fotos-gratis/homem-e-mulher-com-camisas-polo-brancas-basicas-fotos-de-estudio-de-roupas_53876-104297.jpg?w=1380&t=st=1691414399~exp=1691414999~hmac=c91d823e3f4ac25bc019cfe97d1ac46e9004b5c05c1c926a1628dea6c50f7868',
                            }
                        

                        ]
                    },
                    {
                        color: '#000',
                        
                    },
                    {
                        color: '#fff',
                        images : [ 
                            {
                                id: 0,
                                img:'https://img.freepik.com/fotos-premium/ciclista-urbana-tendo-um-freio-na-e-bicicleta_23-2148225823.jpg?w=1380',
                            },
                            {
                                id: 1,
                                img: 'https://img.freepik.com/fotos-gratis/pessoa-usando-scooter-eletrico-na-cidade_23-2149341734.jpg?w=1380&t=st=1691414099~exp=1691414699~hmac=f35fb48d4effe714f5a8360f85cf978697e1990644064279483c725b48b4cb60',
                            }
                        ]
                     
                    }
                ]
                  
            },
            {
                id: 32,
                img: 'https://img.freepik.com/vetores-gratis/modelo-de-camisa-branca_1132-95.jpg?2&w=826&t=st=1691413762~exp=1691414362~hmac=8f9e75638ed0b1eaa8656424d4851502a944ce2edea1d8aacaf59c55538f383f',
                img2: 'https://img.freepik.com/fotos-gratis/homem-e-mulher-com-camisas-polo-brancas-basicas-fotos-de-estudio-de-roupas_53876-104297.jpg?w=1380&t=st=1691414399~exp=1691414999~hmac=c91d823e3f4ac25bc019cfe97d1ac46e9004b5c05c1c926a1628dea6c50f7868',
                nome: 'Camisa Enerzee',
                preco: 'R$120,00',
                clothing: true,
                colors: [
                    {
                        color: '#2D247A',
                        images : [ 
                            {
                                id: 0,
                                img: 'https://img.freepik.com/vetores-gratis/modelo-de-camisa-branca_1132-95.jpg?2&w=826&t=st=1691413762~exp=1691414362~hmac=8f9e75638ed0b1eaa8656424d4851502a944ce2edea1d8aacaf59c55538f383f',
                            },
                            {
                                id: 1,
                                img: 'https://img.freepik.com/fotos-gratis/homem-e-mulher-com-camisas-polo-brancas-basicas-fotos-de-estudio-de-roupas_53876-104297.jpg?w=1380&t=st=1691414399~exp=1691414999~hmac=c91d823e3f4ac25bc019cfe97d1ac46e9004b5c05c1c926a1628dea6c50f7868',
                            }
                        

                        ]
                    },
                    {
                        color: '#000',
                        
                    },
                    {
                        color: '#fff',
                        images : [ 
                            {
                                id: 0,
                                img:'https://img.freepik.com/fotos-premium/ciclista-urbana-tendo-um-freio-na-e-bicicleta_23-2148225823.jpg?w=1380',
                            },
                            {
                                id: 1,
                                img: 'https://img.freepik.com/fotos-gratis/pessoa-usando-scooter-eletrico-na-cidade_23-2149341734.jpg?w=1380&t=st=1691414099~exp=1691414699~hmac=f35fb48d4effe714f5a8360f85cf978697e1990644064279483c725b48b4cb60',
                            }
                        ]
                     
                    }
                ]
                  
            },
            {
                id: 15,
                img: 'https://img.freepik.com/vetores-gratis/modelo-de-camisa-branca_1132-95.jpg?2&w=826&t=st=1691413762~exp=1691414362~hmac=8f9e75638ed0b1eaa8656424d4851502a944ce2edea1d8aacaf59c55538f383f',
                img2: 'https://img.freepik.com/fotos-gratis/homem-e-mulher-com-camisas-polo-brancas-basicas-fotos-de-estudio-de-roupas_53876-104297.jpg?w=1380&t=st=1691414399~exp=1691414999~hmac=c91d823e3f4ac25bc019cfe97d1ac46e9004b5c05c1c926a1628dea6c50f7868',
                nome: 'Camisa Enerzee',
                preco: 'R$120,00',
                clothing: true,
                colors: [
                    {
                        color: '#2D247A',
                        images : [ 
                            {
                                id: 0,
                                img: 'https://img.freepik.com/vetores-gratis/modelo-de-camisa-branca_1132-95.jpg?2&w=826&t=st=1691413762~exp=1691414362~hmac=8f9e75638ed0b1eaa8656424d4851502a944ce2edea1d8aacaf59c55538f383f',
                            },
                            {
                                id: 1,
                                img: 'https://img.freepik.com/fotos-gratis/homem-e-mulher-com-camisas-polo-brancas-basicas-fotos-de-estudio-de-roupas_53876-104297.jpg?w=1380&t=st=1691414399~exp=1691414999~hmac=c91d823e3f4ac25bc019cfe97d1ac46e9004b5c05c1c926a1628dea6c50f7868',
                            }
                        

                        ]
                    },
                    {
                        color: '#000',
                        
                    },
                    {
                        color: '#fff',
                        images : [ 
                            {
                                id: 0,
                                img:'https://img.freepik.com/fotos-premium/ciclista-urbana-tendo-um-freio-na-e-bicicleta_23-2148225823.jpg?w=1380',
                            },
                            {
                                id: 1,
                                img: 'https://img.freepik.com/fotos-gratis/pessoa-usando-scooter-eletrico-na-cidade_23-2149341734.jpg?w=1380&t=st=1691414099~exp=1691414699~hmac=f35fb48d4effe714f5a8360f85cf978697e1990644064279483c725b48b4cb60',
                            }
                        ]
                     
                    }
                ]
                  
            },
          
         
        ],
        departamentsNames: ['Roupas', 'Veículos']
    }
  },
})
