import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "w-screen bg-white gap-10 flex items-baseline justify-around p-14" }
const _hoisted_2 = { class: "font-bold border-b-4 border-cinza-padrao pb-2 text-xl" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.departaments, (departament) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "w-96",
        key: departament.name
      }, [
        _createElementVNode("p", _hoisted_2, _toDisplayString(departament.name), 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(departament.suboptions, (suboption) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "font-semibold",
            key: suboption
          }, _toDisplayString(suboption), 1))
        }), 128))
      ]))
    }), 128))
  ]))
}