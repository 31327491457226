
import { defineComponent } from 'vue';
import CarouselDisplay from '@/components/CarouselDisplay.vue';
import ProductsDisplay from '@/components/ProductsDisplay.vue';
// import BannersDepartaments from '@/components/BannersDepartaments.vue';
import Footer from '@/components/Footer.vue';

export default defineComponent({
    name: "ShopHome",
    components: {
    CarouselDisplay,
    ProductsDisplay,
    // BannersDepartaments,
    Footer,
},

data() {
    return {
        departamentos: [
            {
                img: 'https://img.freepik.com/fotos-gratis/retrato-de-uma-menina-atraente-e-adulta-com-coque-ruivo_295783-13422.jpg?w=1380&t=st=1691431788~exp=1691432388~hmac=2332d07618f1541367f8cb30c3726fe574352c5a621018f7e7d39977192cedb2',
                nome: "Vestuario feminino"
            },
            {
                img: 'https://img.freepik.com/fotos-gratis/jovem-de-oculos-pretos-usando-uma-camisa-polo-cinza-olhando-para-o-lado-com-expressao-confiante-em-pe-sobre-a-parede-azul_141793-57081.jpg?w=1380&t=st=1691431525~exp=1691432125~hmac=93913c24bd34c6d046121da416cd1f83926ee3f062fbb7a1fbcd75f9dd551876',
                nome: "Vestuario masculino"
            },
            {
                img: 'https://img.freepik.com/fotos-gratis/garotinho-olhando-confuso_23-2148244770.jpg?w=1380&t=st=1691431573~exp=1691432173~hmac=48c3b6f386bd2a8651a3ea12279bf4c1a00dd094e7c7c53a06c2143c3c0b721a',
                nome: "Vestuario infantil"
            },
        ]
    }
}

})
